const state = {
  orbitControls: {
    position: null,
    target: null,
  },
  selected_map_format: null,
  start_monitor: false,
  selected_space_qr_code: null,
  selected_space: null,
};
const getters = {
  getOrbitControlsData(state) {
    return state.orbitControls;
  },
  getSelectedMapFormat(state) {
    return state.selected_map_format;
  },
  getSelectedSpaceQRCode(state) {
    return state.selected_space_qr_code;
  },
  isRunningMonitor(state) {
    return state.start_monitor;
  },
  getSelectedSpace(state) {
    return state.selected_space;
  },
};
const mutations = {
  saveOrbitControls(state, payload) {
    if (payload) {
      state.orbitControls.position = payload.position;
      state.orbitControls.target = payload.target;
    } else {
      state.orbitControls.position = null;
      state.orbitControls.target = null;
    }
  },
  setMapFormat(state, payload) {
    state.selected_map_format = payload;
  },
  setSelectedSpaceQRCode(state, payload) {
    state.selected_space_qr_code = payload;
  },
  setStartMonitor(state, payload) {
    state.start_monitor = payload;
  },
  setSelectedSpace(state, payload) {
    state.selected_space = payload;
  },
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
