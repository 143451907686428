import axios from "axios";
const apiLoader = process.env.VUE_APP_MAXBOARD;

const state = {
  schedulings: null,
  average_ocupation: null,
  all_floors_usage: null,
  current_floor_usage: null,
  finish: false,
};
const getters = {
  getScheduling(state) {
    return state.schedulings;
  },
  getAverageOcupation(state) {
    return state.average_ocupation;
  },
  getAllFloorsUsage(state) {
    return state.all_floors_usage;
  },
  getCurrentFloorUsage(state) {
    return state.current_floor_usage;
  },
  loadedAllSchedulings(state) {
    return state.finish;
  },
};
const mutations = {
  setAverageOcupation(state, payload) {
    state.average_ocupation = payload;
  },
  setAllFloorsUsage(state, payload) {
    state.all_floors_usage = payload;
  },
  setCurrentFloorUsage(state, payload) {
    state.current_floor_usage = payload;
  },
  setFinish(state) {
    state.finish = true;
  },
};
const actions = {
  loadSchedulings({ dispatch, commit, state, getters }, newUrl) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        if (!getters.getScheduling) state.schedulings = [];
        let url =
          newUrl ??
          `${apiLoader}/device/maxboard/schedulings?page=1&limit=30&floorId=${getters.getLocality.floor.id}&lang=${getters.getSelectedLanguage}`;

        while (url) {
          const response = await axios.get(url, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getters.getSession}`,
            },
          });

          const nextLink = response.data.links.next;
          if (response.data.data.length > 0) {
            response.data.data.forEach((space) =>
              dispatch("setScheduling", space)
            );
            url = nextLink; // Atualiza a URL para o próximo link
          } else {
            console.log("No schedules found");
            url = null; // Termina o loop
          }
        }
        commit("setFinish");
        resolve(); // Resolve a Promise após o loop
      } catch (error) {
        if (error.response) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      }
    });
  },
  setScheduling({ getters }, payload) {
    const found_base = getters.getScheduling.findIndex(
      (e) => e.id === payload.id
    );
    if (found_base === -1) {
      state.schedulings.push(payload);
    } else {
      state.schedulings[found_base] = payload;
    }
  },
  removeScheduling({ state, getters }, payload) {
    return new Promise((resolve, reject) => {
      const index = getters.getScheduling.findIndex(
        (space) => space.id === parseInt(payload.canceled.id)
      );
      if (index > -1) {
        const space_index = state.schedulings[index].schedulings.findIndex(
          (scheduling) => scheduling.id === payload.canceled.schedulings[0].id
        );
        if (space_index > -1) {
          state.schedulings[index].schedulings.splice(space_index, 1);
          resolve();
        } else {
          reject("Scheduling not found", payload.canceled.schedulings[0].id);
        }
      } else {
        reject("Space not found", payload.canceled.id);
      }
    });
  },
  updateScheduling({ state, getters }, payload) {
    return new Promise((resolve, reject) => {
      const index = getters.getScheduling.findIndex(
        (e) => e.id === parseInt(payload.id)
      );
      if (index > -1) {
        state.schedulings[index].schedulings = payload.schedulings;
        resolve();
      } else {
        reject("Space not found", payload.id);
      }
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
