import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import board from "./modules/board";
import map from "./modules/map";
import schedulings from "./modules/schedulings";
import themes from "./modules/themes";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const apiLoader = process.env.VUE_APP_MAXBOARD;

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "workplace3D",
      paths: [
        "session",
        "sessionData",
        "board",
        "map.orbitControls",
        "map.selected_map_format",
        "themes.current_theme",
        "themes.themes",
      ],
    }),
  ],
  state: {
    session: null,
    sessionData: null,
  },
  getters: {
    getAllConfigs(state, getters) {
      if (
        state.session &&
        getters.getCompanys &&
        getters.getLocality &&
        getters.getBases &&
        getters.getAverageBase &&
        getters.getOrbitControlsData
      ) {
        return true;
      } else {
        return false;
      }
    },
    getSession(state) {
      if (!state.session) {
        return null;
      } else {
        return state.session.accessToken;
      }
    },
    getSessionData(state) {
      return state.sessionData;
    },
    getExpired(state) {
      return state.session.accessTokenExpiration;
    },
  },
  mutations: {
    setSession(state, payload = null) {
      if (payload) state.session = payload;
      else state.session = null;
    },
    setSessionData(state, payload = null) {
      if (payload) state.sessionData = payload;
      else state.sessionData = null;
    },
  },
  actions: {
    async refreshSession({ commit, state, getters }) {
      const currentTime = new Date().getTime();
      if (state.session.refreshTokenExpiration > currentTime) {
        let result = false;
        await axios
          .post(
            `${apiLoader}/auth/login/maxboard/refresh?lang=${getters.getSelectedLanguage}`,
            {},
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${state.session.refreshToken}`,
              },
            }
          )
          .then((response) => {
            console.log("roudou aqui", response.data);
            commit("setSession", response.data);
            result = true;
          })
          .catch((error) => {
            console.log("deu errro", error);
          });
        return result;
      } else {
        commit("setSession", null);
        return false;
      }
    },
    async sessionActive({ dispatch, state }) {
      if (!state.session) return false;

      const currentTime = new Date().getTime();
      if (state.session.accessTokenExpiration < currentTime) {
        const refreshToken = await dispatch("refreshSession");
        if (!refreshToken) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    logoutSession({ commit }) {
      commit("setSession", null);
      commit("setSelectedLanguage", null);
      commit("setCompany", null);
      commit("setLocality", null);
      commit("setBases", null);
      commit("setAverageBase", null);
      commit("saveOrbitControls", null);
    },
    cleanProjectData({ commit }) {
      commit("setCompany", null);
      commit("setLocality", null);
      commit("setBases", null);
      commit("setAverageBase", null);
      commit("saveOrbitControls", null);
    },
  },
  modules: {
    board,
    map,
    schedulings,
    themes,
  },
});
